import { useState, useEffect, useContext } from 'react';
import { PageData } from '../ContextAPI/context';
import { CreatedNewObj, getTargetedValue, updateTargetedValue, getTargetedCondition, getSelectedSection } from '../Actions/common';
import TextField from './commonComp/TextField';
import CustomSubheader from './CustomSubheader';
import { UPDATE_STATE } from '../Constant';
import { TextEditor } from './commonComp/TextEditor';
import CustomHeader from './commonComp/CustomHeader';
import InputSelect from './commonComp/InputSelect';
import InputCheckbox from './commonComp/InputCheckbox';

const ProductDetails = props => {
  const { PrePurchaseConfigSetting, unifiedEditor, dispatch } = useContext(PageData);
  const [titleComp, setTitle] = useState('');
  const [objComp, objstate] = useState([props]);
  const [state, setState] = useState();
  const { id, sectionID, topSectionID, extentionID, target } = props.type;

  useEffect(() => {
    const fetchData = async () => {
      setState(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1]);
      const latestPrepurchaseSettings = PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1];
      const data = getTargetedValue(latestPrepurchaseSettings, target, id, sectionID, topSectionID, extentionID)
      const obj = await getSelectedSection(data, unifiedEditor.sections)
      objstate(obj)
      setTitle(obj?.blockName);
    }

    fetchData()

  }, [PrePurchaseConfigSetting, unifiedEditor, target, id, sectionID, topSectionID, extentionID]);

  const handleChange = async (e, target) => {
    let value = e;
    const data = await CreatedNewObj(state);
    const dataValue = await updateTargetedValue(data, target, value, id, sectionID, topSectionID, extentionID);
    if (dataValue) {
      dispatch({ type: UPDATE_STATE, payload: dataValue });
    }
  };

  try {
    return (
      <>
        <div className="rightbar-content">
          {objComp.count > 0 ? (
            <>
              <CustomHeader label={titleComp} />
              {objComp.blockSettings.map((x, i) => {
                return (

                  <div key={i}>
                    <CustomSubheader label={x.label} />
                    {x.settingGroups.map((sub, index) => {
                      if (
                        getTargetedCondition(state, `${target}.${sub.target}`, sub.condition, id, sectionID, topSectionID, extentionID) !== true &&
                        getTargetedCondition(state, `${target}.${sub.target}`, sub.condition, id, sectionID, topSectionID, extentionID) !== 'custom_text' &&
                        sub.conditional === true
                      ) {
                        return null;
                      } else {
                        return (

                          <div key={index}>
                            {sub.type === 'checkbox' ? (
                              <InputCheckbox
                                label={sub.label}
                                checked={getTargetedValue(state, `${target}.${sub.target}`, id, sectionID, topSectionID, extentionID)}
                                onChange={e => handleChange(e, `${target}.${sub.target}`)}
                                showTooltip={sub.showTooltip}
                                tooltipText={sub.tooltipText}
                              />
                            ) : sub.type === 'dropdown' ? (
                              <InputSelect
                                isShowTooltip={sub.showTooltip}
                                tooltipContent={sub.tooltipText}
                                label={sub.label}
                                options={sub.options}
                                onChange={e => handleChange(e, `${target}.${sub.target}`)}
                                value={getTargetedValue(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1], `${target}.${sub.target}`, id, sectionID, topSectionID, extentionID)}
                              />
                            ) : sub.type === 'textField' ? (
                              <TextField
                                label={sub.label}
                                value={getTargetedValue(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1], `${target}.${sub.target}`, id, sectionID, topSectionID, extentionID)}
                                onChange={e => handleChange(e, `${target}.${sub.target}`)}
                                toolTipIsEnable={sub.showTooltip}
                                tooltip={sub.tooltipText}
                              />
                            ) : sub.type === 'textEditor' ? (
                              <TextEditor
                                label={sub.label}
                                tooltipText={sub.tooltipText}
                                defaultText={sub.defaultText}
                                showTooltip={sub.showTooltip}
                                value={getTargetedValue(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1], `${target}.${sub.target}`, id, sectionID, topSectionID, extentionID)}
                                onChange={(e) => handleChange(e, `${target}.${sub.target}`)}
                              />
                            ) : null}
                          </div>


                        );
                      }
                    })}
                    {objComp?.blockSettings?.length - 1 !== i && <div className='content-divider' />}
                  </div>

                );
              })}
            </>
          ) : null}
        </div>
      </>
    );
  } catch (err) {
    return <></>;
  }
};

export default ProductDetails;
