import React, { useState, useContext, useCallback, useEffect } from 'react';
import { Button, Icon, AppProvider, Badge } from '@shopify/polaris';
import { PlayMinor, RedoMajor, UndoMajor } from '@shopify/polaris-icons';
import '@shopify/polaris/build/esm/styles.css';
import './../../config';
import { PageData } from './../../ContextAPI/context';
import Switch from './../commonComp/Switch';
import ViewportActionList from './../ViewportActionList';
import ExitButton from './../ExitButton';
import {
  CreatedNewObj,
  getTargetedValue,
} from '../../Actions/common';
import Toast from '../commonComp/Toast';
import PopoverWithActionList from '../commonComp/PopoverWithActionList';
import { HELP_VIDEO_DATA, UPDATE_STATE, WIX } from '../../Constant';
import { useToast } from '@chakra-ui/react';

function Header(props) {
  const navBarJSON = props.data;
  const [active, setActive] = useState(false);
  const [toastMessage, setToastMessage] = useState('Changes saved')
  const [isError, setIsError] = useState(false)
  const {
    PrePurchaseConfigSetting,
    container,
    submitData,
    selectedPage,
    handlePageChange,
    undoFun,
    redoFun,
    dispatch,
    setHelpModalData,
    type,
    requiredData,
    setRequiredData,
    isRequiredDataFilled
  } = useContext(PageData);
  const toast = useToast()

  const handleChange = async (e) => {
    if (isRequiredDataFilled()) {
      let value = e.target.checked;
      let latestPrepurchaseSettings = await CreatedNewObj(
        PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1]
      );
      if (navBarJSON?.pagesOptions?.length > 0) {
        latestPrepurchaseSettings = {
          ...latestPrepurchaseSettings,
          [`disable${selectedPage || 'Information'}Page`]: !value
        }
      } else {
        if (typeof PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1]?.status == "boolean") {
          latestPrepurchaseSettings = {
            ...latestPrepurchaseSettings,
            status: value
          }
        } else {
          latestPrepurchaseSettings = {
            ...latestPrepurchaseSettings,
            status: value ? 'enabled' : 'disabled'
          }
        }
      }

      dispatch({ type: UPDATE_STATE, payload: latestPrepurchaseSettings });
    }
  };

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const handleSubmit = async () => {
    if (isRequiredDataFilled()) {
      let isDataSaved = await submitData();
      let isWix = type === WIX;
      if (isDataSaved) {
        if (isWix) {
          toast({
            description: "Changes saved",
            status: 'success',
            duration: 9000,
            isClosable: true,
          })
        } else {
          setToastMessage('Changes saved')

        }
        setIsError(false)
      } else {
        if (isWix) {
          toast({
            description: "Server error",
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        } else {
          setToastMessage('Server error')
        }

        setIsError(true)
      }

      toggleActive();
    }
  };

  const handleLinkClick = () => {
    setHelpModalData(HELP_VIDEO_DATA)
  }

  useEffect(() => {
    if (requiredData.showToast) {
      setToastMessage('You need to select a product')
      setIsError(true)
      setRequiredData({ ...requiredData, showToast: false })
      toggleActive()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiredData.showToast])

  const isPageLive = useCallback(() => {
    if (navBarJSON?.pagesOptions?.length > 0) {
      if (navBarJSON.status === 0 || getTargetedValue(
        PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1],
        `disable${selectedPage || 'Information'}Page`
      )) {
        return true
      }
    } else {
      if (PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1]?.status === 'disabled' || !PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1]?.status) {
        return true
      }
    }
    return false
  }, [PrePurchaseConfigSetting, selectedPage, navBarJSON.status, navBarJSON?.pagesOptions])

  const getBadge = (className) => {
    return (
      <div className={`badge-container ${className ? className : ''}`}>
        <Badge
          status={`${isPageLive() ? 'attention' : 'success'}`}
          progress={`${isPageLive() ? 'incomplete' : 'complete'}`}
        >
          {isPageLive() ? 'Draft' : 'Live'}
        </Badge>
      </div>
    )
  }

  return (
    <AppProvider>
      <div className="main-header">
        <div className="header-left-section">
          <div className='exit-button-container'>
            <ExitButton />
          </div>

          <div className="px-3 title-text">{navBarJSON.itemName}</div>

          {type !== WIX && getBadge()}

          <div>
            <Button onClick={handleLinkClick} icon={<Icon source={PlayMinor} />} >
              Help Video
            </Button>
          </div>
        </div>

        <div className="header-select-dropdown">
          {navBarJSON?.pagesOptions?.length > 0 &&
            <PopoverWithActionList
              onChange={handlePageChange}
              suffix={'page'}
              value={selectedPage || 'Information'}
              options={navBarJSON.pagesOptions}
            />
          }
        </div>

        <div className="header-right-section">
          <ViewportActionList />

          <div className="undo-redo-btn">
            <div className="nav-view-button">
              <Button
                plainp
                disabled={PrePurchaseConfigSetting.length <= 1}
                onClick={undoFun}
              >
                <Icon source={UndoMajor} />
              </Button>
            </div>

            <div className="nav-view-button">
              <Button plain disabled={container.length < 1} onClick={redoFun}>
                <Icon source={RedoMajor} />
              </Button>
            </div>
          </div>
          {type === WIX && getBadge('badge-spacing')}
          <div className="header-switch-container">
            <Switch
              isOn={!isPageLive()}
              onColor="#007F60"
              offColor="#919EAB"
              handleToggle={handleChange}
            />
          </div>

          <div className="px-3">
            <Button
              primary
              disabled={PrePurchaseConfigSetting.length < 2}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </div>
          {type !== WIX &&
            <Toast
              active={active}
              content={toastMessage}
              error={isError}
              toggleActive={toggleActive}
            />
          }
        </div>
      </div>
    </AppProvider>
  );
}

export default Header;
