import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Frame } from '@shopify/polaris';
import '../style/index.css';
import { PageData } from '../ContextAPI/context';
import Header from './Header';
import LeftBar from './LeftBar';
import RightSideBar from './RightSideBar';
import PrePurchaseProduct from '../mock/product.json';
import { BACK, CHECKOUT, CUSTOMIZATIONS, REACT_APP_TITLE, SHOP, WIX } from '../Constant';
import HelpModal from './HelpModal/index'
import LeftActionBar from './LeftActionBar';
import { AppProvider } from "@shopify/polaris";
import en from '@shopify/polaris/locales/en.json';
import { ChakraProvider, Progress } from '@chakra-ui/react';
import AddUpsellPopup from './AddUpsellPopup';

const Progressbar = () => {
  const [count, countState] = useState(0);

  useEffect(() => {
    for (let index = 0; index <= 1000; index++) {
      countState(index / 10);
    }
  }, []);

  return (
    <>
      <Progress className='layout-progress-bar' value={count} size='xs' colorScheme='green' />
    </>
  );
};

const Layout = ({ children }) => {
  let { progress, PrePurchaseConfigSetting, type, bannerStatus, unifiedEditor, selectedPage, hmacRes, previewSelectedSections, setRightbarLinks, isRightbarEmpty, setIsRightbarEmpty, setBannerStatus, setSelectedLink, isRequiredDataFilled } = useContext(PageData);
  const [RightBarValue, rightBarState] = useState('');
  const [removedId, setRemoveId] = useState()
  const [loading, setLoading] = useState(true)
  const [selectedAction, setSelectedAction] = useState(CUSTOMIZATIONS)

  useEffect(() => {
    window['__react-beautiful-dnd-disable-dev-warnings'] = true;
    const params = window.location.search
    let shop = new URLSearchParams(params).get(SHOP);
    if (shop) {
      shop = shop.split('.')
      document.title = `${shop[0]} • ${REACT_APP_TITLE}`
    } else {
      document.title = REACT_APP_TITLE
    }
  }, [])

  const Change = (e) => {
    if (isRequiredDataFilled()) {
      if (!e) {
        handleBack(BACK)
      } else {
        const maintype = e.currentTarget.getAttribute('data-component');
        const subtype = e.currentTarget.getAttribute('data-subcomponent');
        const mincount = e.currentTarget.getAttribute('data-mincount');
        const count = e.currentTarget.getAttribute('data-count');
        const id = e.currentTarget.getAttribute('data-id');
        const location = e.currentTarget.getAttribute('data-location');
        const sectionID = e.currentTarget.getAttribute('data-section-id');
        const topSectionID = e.currentTarget.getAttribute('data-top-section-id');
        const extentionID = e.currentTarget.getAttribute('data-extention-id');
        const target = e.currentTarget.getAttribute('data-target');

        let width = window.innerWidth
        if (width <= 1614 && RightBarValue?.sectionID !== sectionID) {
          setLoading(true)
        }

        rightBarState({
          main: maintype,
          sub: subtype,
          count,
          minCount: mincount,
          id,
          sectionID,
          location,
          topSectionID,
          extentionID,
          target,
        });
      }
    }
  };

  const handleBack = useCallback((e) => {
    if (e === BACK) {
      rightBarState('');
      setRightbarLinks([])
    }
  }, [setRightbarLinks, rightBarState]);

  useEffect(() => {
    if (isRightbarEmpty) {
      rightBarState(prevState => ({ ...prevState, main: '' }));
      setIsRightbarEmpty(false);
    }
  }, [isRightbarEmpty, setIsRightbarEmpty]);

  useEffect(() => {
    window.addEventListener("hidePreviewBanner", (e) => {
      setRemoveId(e.detail.id)
    });

    window.addEventListener("customeEvent", (e) => {
      setSelectedLink(e.detail)
    });
  }, [setSelectedLink])

  useEffect(() => {
    if (removedId) {
      let obj = bannerStatus
      delete obj[removedId]
      setBannerStatus(obj)
      setRemoveId()
    }
  }, [removedId, setBannerStatus, bannerStatus])

  const handleActionChange = (value) => {
    setSelectedAction(value)
  }

  const getMainLayout = (isWixEditor) => {
    return (
      <div style={{ maxHeight: '100vh', overflow: 'hidden' }} className={isWixEditor ? 'wix-editor' : ''}>
        {progress ? <Progressbar /> : ''}
        {/* Header section of unified */}
        <Header data={unifiedEditor} />
        <div className="navigationWrapper">
          <section className="main-layout">
            <div className="main-layout-inner">
              {/* Left section of unified */}
              {type !== WIX && <div
                className='left-action-menu'
              >
                <LeftActionBar selectedAction={selectedAction} onChange={handleActionChange} />
              </div>}
              <div
                className={
                  RightBarValue.length === 0
                    ? 'main-layout-left'
                    : 'main-layout-left sidebar-hide'
                }
              >
                <LeftBar onClick={Change} />
              </div>
              <div className={`main-layout-center ${type === CHECKOUT ? 'ce-preview' : ''}`}>
                {/* Prepurchase preview section */}
                <main className="center-section">
                  {children}
                  {
                    type === CHECKOUT ? <checkout-upsell-preview
                      value={JSON.stringify({
                        productData: PrePurchaseProduct,
                        checkoutSchema:
                          PrePurchaseConfigSetting[
                          PrePurchaseConfigSetting.length - 1
                          ],
                        page: selectedPage,
                        bannerStatus: bannerStatus,
                        selectedSection: previewSelectedSections,
                        view: PrePurchaseConfigSetting.length > 1 && PrePurchaseConfigSetting[
                          PrePurchaseConfigSetting.length - 1
                        ]?.['isMobileView'] ? 'Mobile' : 'Desktop',
                        currency: hmacRes?.currency,
                        currencySymbol: hmacRes?.currencySymbol
                      })}
                    ></checkout-upsell-preview> :

                      type === WIX ? <wix-checkout-preview
                        value={JSON.stringify({
                          productData: PrePurchaseProduct,
                          checkoutSchema:
                            PrePurchaseConfigSetting[
                            PrePurchaseConfigSetting.length - 1
                            ],
                          page: selectedPage,
                          bannerStatus: bannerStatus,
                          selectedSection: previewSelectedSections,
                          view: PrePurchaseConfigSetting.length > 1 && PrePurchaseConfigSetting[
                            PrePurchaseConfigSetting.length - 1
                          ]?.['isMobileView'] ? 'Mobile' : 'Desktop',
                          currency: hmacRes?.currency,
                          currencySymbol: hmacRes?.currencySymbol
                        })}
                      ></wix-checkout-preview> :
                        <pre-purchase-widget
                          value={JSON.stringify({
                            isPreview: true,
                            productData: PrePurchaseProduct,
                            prePurchaseSetting: PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1]
                          })}
                        >
                        </pre-purchase-widget>
                  }
                </main>
              </div>
              {/* Right section of unified */}
              <div
                className={
                  RightBarValue.length !== 0
                    ? 'main-layout-left'
                    : 'main-layout-left sidebar-hide'
                }
              >
                <RightSideBar
                  renderComponent={RightBarValue}
                  backButton={handleBack}
                  loading={loading}
                  setLoading={setLoading}
                />
              </div>
            </div>
          </section>
        </div>
        <HelpModal />
        <AddUpsellPopup />
      </div>
    )
  }

  return (
    type === WIX ?
      <ChakraProvider>
        {getMainLayout(true)}
      </ChakraProvider>
      :
      <AppProvider i18n={en}>
        <Frame>
          {getMainLayout()}
        </Frame>
      </AppProvider>
    // <AppProvider i18n={en}>
    //   <Frame>
    //     {getMainLayout()}
    //   </Frame>
    // </AppProvider>
  );
};

export default Layout;
