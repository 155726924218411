import { BANNER } from '../Constant';

/* Get the Object value by the target Value (String) 
e.g :-  "sections.[sectionType:title].sectionSettings.titleText" */
const _ = require('lodash');

export function getTargetedValue(data, str, id, sectionID, topSectionID, extensionID) {
  let count = 0;
  str = replaceTargetIDs(str, id, sectionID, topSectionID, extensionID)
  str = str?.split('.');
  let num = str?.length - 1;
  let newValue;

  function targetValue(obj, num, arr, count) {
    try {
      if (num <= 0) {
        let arrayValue = arr[count];
        newValue = obj[`${arrayValue}`];
        if (obj[`${arrayValue}`]) return obj[`${arrayValue}`];
      }

      let arrayValue = arr[count];
      if (arrayValue.includes('[')) {
        arrayValue = arrayValue.replace(/([^a-z0-9_/:]+)/gi, '');
        let valuenew = arrayValue.split(':');

        let index = obj.findIndex(x => x[valuenew[0]].toString().toLowerCase() === valuenew[1].toLowerCase());
        arrayValue = index;
      }
      count++;
      if (count >= arr.length) {
        newValue = obj[`${arrayValue}`];
        return obj[`${arrayValue}`];
      }
      targetValue(obj[`${arrayValue}`], num - 1, arr, count);
    } catch (err) {
    }
  }
  targetValue(data, num, str, count);

  if (newValue !== undefined) {
    return newValue;
  }
}

export function checkDynamicRender(extension) {
  let result = false
  if (extension && extension?.renderPoint?.includes("Dynamic")) {
    result = true
  }
  return result
}

export const getTargetedCondition = (state, target, newval, id, sectionID, topSectionID, extensionID) => {
  target = replaceTargetIDs(target, id, sectionID, topSectionID, extensionID)
  target = getTargetString(target, 1, 1);
  return getTargetedValue(state, target)?.[newval];
};

export const getTargeted = (state, target, index, id, sectionID, topSectionID, extensionID) => {
  target = replaceTargetIDs(target, id, sectionID, topSectionID, extensionID)
  target = getTargetString(target, index, 1);
  return getTargetedValue(state, target);
};

export const replaceTargetIDs = (target, id, sectionID, topSectionID, extensionID) => {
  if (target) {
    target = target.replace('{id}', id);
    target = target.replace('{SECTION_ID}', sectionID);
    target = target.replace('{TOP_SECTION_ID}', topSectionID);
    target = target.replace('{EXTENTION_ID}', extensionID);
  }
  return target
}

export const getTargetString = (target, index = 1, i = 1) => {
  target = target?.split('.');
  target?.splice(target.length - index, i);
  return target?.join('.')
}

/* Update the Object value by the target Value (String) 
e.g :-  "sections.[sectionType:title].sectionSettings.titleText" */
export const updateTargetedValue = (data, str, value, id, sectionID, topSectionID, extensionID) => {
  str = replaceTargetIDs(str, id, sectionID, topSectionID, extensionID)
  let count = 0;
  str = str.split('.');
  let num = str.length - 1;
  let checking;

  function targetValue(obj, num, arr, count, value) {
    if (!value && typeof value !== 'boolean') {
      value = ' ';
    }
    try {
      if (num <= 0) {
        let arrayValue = arr[count];
        obj[`${arrayValue}`] = value;
        checking = typeof value === 'boolean' ? 'checking' : value;
        return obj;
      }
      let arrayValue = arr[count];

      if (arrayValue.includes('[')) {
        arrayValue = arrayValue.replace(/([^a-z0-9/:]+)/gi, '');
        let valuenew = arrayValue.split(':');

        let index = obj.findIndex(x => x[valuenew[0]].toString().toLowerCase() === valuenew[1].toLowerCase());
        arrayValue = index;
      }
      count++;
      targetValue(obj[`${arrayValue}`], num - 1, arr, count, value);
    } catch (err) { }
  }
  targetValue(data, num, str, count, value);
  if (checking) {
    return data;
  }
}

export function getState(state, props, type) {
  const { sub, id, sectionID, topSectionID } = props.type;
  let index, subIndex, textValue, obj, title, mainObj;
  let sections = state.sections;
  if (topSectionID) {
    let matchingSection = state.topLevelSections.findIndex(x => Number(x.id) === topSectionID);
    sections = state.topLevelSections[matchingSection].sections;
  }

  try {
    if (sub === 'main') {
      index = sections.findIndex(x => Number(x.id) === sectionID);
      subIndex = sections[index].sectionSettings.findIndex(x => x.type.toLowerCase() === type.toLowerCase());
      obj = sections[index].sectionSettings;
      title = sections[index].sectionName;
    } else {
      try {
        index = sections.findIndex(x => Number(x.id) === sectionID);
        subIndex = sections[index].blocks.findIndex(x => Number(x.id) === Number(id));
        obj = sections[index].blocks[subIndex].blockSettings;
        title = sections[index].blocks[subIndex].blockName;
        mainObj = sections[index].blocks[subIndex];
      } catch (err) { }
    }
  } catch (err) { }
  return { textValue, obj, title, mainObj };
}


export async function CreatedNewObj(state) {
  const { sectionAdded, ...rest } = state
  let data = JSON.stringify(rest);
  data = await JSON.parse(data);
  return data;
}

export function Ids() {
  var milliseconds = new Date().getTime();
  return milliseconds;
}

export const countDec = async (type, obj) => {
  let data = obj.filter(x => x.blockName === type);
  const send = await data.map(x => (x.count = x.count - 1));
  return send;
};

export const countInc = async (type, obj) => {
  let data = obj.filter(x => x.blockName === type);
  const send = await data.map(x => (x.count = x.count + 1));
  return send;
};

export const getSections = async (data, sections, target, newTarget = '[sectionID:{SECTION_ID}]', key = 'sectionID') => {
  let arr = []

  if (sections.length > 0) {
    for (let i = 0; i < sections.length; i++) {
      if (sections[i].type === 'group') {
        const topSectionTarget = `[sectionID:{TOP_SECTION_ID}]`
        let index = data.findIndex((item) => item.type === sections[i].type)

        let sectionArr = await getSections(data, sections[i].sections, `${target}.${topSectionTarget}.sections`)
        arr.push({ ...data[index], id: sections[i][key], target: `${target}.${topSectionTarget}`, sections: sectionArr })

      } else {
        let index = data.findIndex((item) => item.type === sections[i].type)
        if (data[index].blocks) {
          let newBlocks = await getBlocks(data[index].blocks, sections[i].blocks, `${target}.${newTarget}.blocks`, '[id:{id}]', 'id')

          arr.push({ ...data[index], id: sections[i][key], target: `${target}.${newTarget}`, blocks: newBlocks })
        } else {
          if (sections[i].type === BANNER) {
            data[index].icon = sections[i].sectionSettings.bannerSettings.bannerStatus + '.svg'
          }
          arr.push({ ...data[index], id: sections[i][key], target: `${target}.${newTarget}` })
        }
      }
    }
  }
  return arr
}


export const getBlocks = async (data, sections, target, newTarget = '[sectionID:{SECTION_ID}]', key = 'sectionID') => {
  let arr = []
  let newArr = _.cloneDeep(data)

  if (sections.length > 0) {
    for (let i = 0; i < sections.length; i++) {

      let index = data.findIndex((item) => item.type === sections[i].type)
      newArr[index].isAdded = true
      let matchingArr = sections.filter((item) => item.type === sections[i].type)

      let obj = _.cloneDeep(data[index])
      if (sections[i].location) {
        obj.location = sections[i].location
      }

      arr.push({ ...obj, id: sections[i][key], target: `${target}.${newTarget}`, count: matchingArr.length })
    }

    newArr.map((item, index) => !item.isAdded && arr.splice(index, 0, { ...item, count: 0 }))
  }

  return arr
}

export async function getSelectedSection(data, arr, field = 'sectionName') {
  let obj
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].type === data?.type && (!data.blockSettings || field === 'blockName')) {

      if ((data.location && data.location === arr[i].location) || !data?.location) {
        obj = { ...arr[i], title: arr[i][field] }
        break
      }

    } else if (arr[i].blocks) {
      obj = await getSelectedSection(data, arr[i].blocks, 'blockName')
      if (obj) {
        obj = { ...obj, title: arr[i][field] }
        break
      }
    }
  }
  return obj
}

export async function getGroupData(data, arr,) {
  let newArr = []
  for (let index = 0; index < arr.length; index++) {
    for (let i = 0; i < data.length; i++) {
      if (arr[index].type === data[i].type) {
        let obj = _.cloneDeep(data[i])
        obj.sectionID = Ids() + index + 1
        obj = assignIdsToBlocks(obj)
        newArr.push(obj)
        break
      }

    }
  }
  return newArr
}

export const checkTargetConditions = (state, target, condition, id, sectionID, topSectionID, extensionID, conditionValue) => {
  if (Array.isArray(condition)) {

    const data = getTargeted(state, target, 1, id, sectionID, topSectionID, extensionID)

    // loop through all conditions
    for (let { type, key, value } of condition) {
      if (type === 'single') {
        // if data does not have the exact value, do not show field
        if (data[key] !== value) {
          return false;
        }
      } else if (type === 'multiple') {
        // for multiple type, if data does not match value in the value array, do not show field
        if (!value.includes(data[key])) {
          return false;
        }
      } else if (type === 'multi-key') {
        // for multiple keys if data need to find outer object use this logic
        // in this logic get outer object and go through every key and find targeted value
        let newTarget = getTargetString(target, key.length - 1, key.length - 1)
        let obj = getTargeted(state, newTarget, 1, id, sectionID, topSectionID, extensionID)
        for (let i = 0; i < key.length; i++) {
          obj = obj[key[i]]
        }
        if (obj !== value) {
          return false
        }

      }
    }

    return true;
  } else {
    let value = getTargetedCondition(state, target, condition, id, sectionID, topSectionID, extensionID)
    return value === (conditionValue || true)
  }
}


/**
 * Checks whether field is allowed to be updated or not based on conditions. 
 * @param {object} state - The preview object.
 * @param {string} target - The key string eg. "extensions.[extensionID:{EXTENSION_ID}].sections.[sectionID:{SECTION_ID}]..."
 * @param {object[]} updateConditions - The list of conditions.
 * @param {string} id - The id of the block
 * @param {string} sectionID - The id of the block's section
 * @param {string} topSectionID - The id of the block's top section
 * @param {string} extensionID - The id of the block's extension
 * @returns {boolean} Returns true if conditions are satisfied otherwise returns false.
 */
export const canUpdateField = (state, target, updateConditions, id, sectionID, topSectionID, extensionID) => {
  if (updateConditions == null) return true;

  const data = getTargeted(state, target, 1, id, sectionID, topSectionID, extensionID)

  return evaluateAND(updateConditions, data);
}


/**
 * Performs OR operation on all the provided conditions.
 * @param {object[]} conditions - The list of conditions.
 * @param {object} data - The targeted preview object.
 * @example
 * // returns true
  evaluateOR([
    {
      "type": "equal",
      "key": "blockSettings.compareTo.isEnable",
      "value": true
    }
  ], data);
 * @returns {boolean} Returns true if any condition is true otherwise returns false.
 */
export const evaluateOR = (conditions, data) => {
  for (const condition of conditions) {
    if ("OR" in condition) {
      const orCondition = evaluateOR(condition["OR"], data);
      if (orCondition) return true;
    } else if ("AND" in condition) {
      const andCondition = evaluateAND(condition["AND"], data);
      if (andCondition) return true;
    } else {
      const { type, key, value } = condition;
      if (type === 'equal' && getNestedObjectValue(data, key) === value) return true;
      if (type === 'gt' && getNestedObjectValue(data, key) > value) return true;
    }
  }

  return false;
}

/**
 * Performs AND operation on all the provided conditions.
 * @param {object[]} conditions - The list of conditions.
 * @param {object} data - The targeted preview object.
 * @example
 * // returns true
  evaluateAND([
    {
      "type": "equal",
      "key": "blockSettings.compareTo.isEnable",
      "value": true
    }
  ], data);
 * @returns {boolean} Returns true if all conditions are true otherwise returns false.
 */
export const evaluateAND = (conditions, data) => {
  for (const condition of conditions) {
    if ("OR" in condition) {
      const orCondition = evaluateOR(condition["OR"], data);
      if (!orCondition) return false;
    } else if ("AND" in condition) {
      const andCondition = evaluateAND(condition["AND"], data);
      if (!andCondition) return false;
    } else {
      const { type, key, value } = condition;
      if (type === 'equal' && getNestedObjectValue(data, key) !== value) return false;
      if (type === 'gt' && getNestedObjectValue(data, key) <= value) return false;
    }
  }

  return true;
}


/**
 * Get value from data object for a nested key string.
 * @param {object} data - The data object from which value needs to be retrieved.
 * @param {string} nestedKeyString - The nested key string.
 * @example
 * // returns value of data[blockSettings][originalPrice][isEnable]
 * getNestedObjectValue(data, "blockSettings.originalPrice.isEnable");
 * @returns {any} Returns value from data object for given nested key.
 */
export const getNestedObjectValue = (data, nestedKeyString) => {
  const keys = nestedKeyString.split('.');

  for (let key of keys) {
    data = data[key];
  }

  return data;
}


export const makeRightbarLinks = (data, parentSectionID, type = 'section', isSeprators) => {
  let arr = _.cloneDeep(data)
  let result = []
  let obj = {}

  if (isSeprators) {
    arr = arr.sort((a, b) => a.location - b.location)
  }

  for (let i = 0; i < arr.length; i++) {
    let elementType = arr[i].type

    if ((elementType !== 'divider' && type === 'section') || (arr[i].count !== 0 && type !== 'section')) {
      let suffix = ''

      if (!obj['max' + elementType]) {

        let count = arr.filter((x) => x.type === elementType).length

        if (count > 1) {
          obj[elementType] = 1
        }
        obj['max' + elementType] = count

      }


      if (obj[elementType]) {

        suffix = `(${obj[elementType]})`
        obj[elementType] += 1

      }

      arr[i].name = `Edit ${arr[i][type + 'Name'].toLowerCase()} ${suffix}`
      arr[i].parentSectionID = parentSectionID
      result.push(arr[i])
    }
  }
  return result
}

export const editRightbarLinks = (data, type = 'section') => {
  let arr = _.cloneDeep(data)
  let result = []
  let obj = {}

  for (let i = 0; i < arr.length; i++) {
    let elementType = arr[i].type
  
      let suffix = ''
      if (!obj['max' + elementType]) {
        let count = arr.filter((x) => x.type === elementType).length

        if (count > 1) {
          obj[elementType] = 1
        }
        obj['max' + elementType] = count
      }

      if (obj[elementType]) {
        suffix = `(${obj[elementType]})`
        obj[elementType] += 1
      }

      arr[i].name = `Edit ${arr[i][type + 'Name'].toLowerCase()} ${suffix}`
      result.push(arr[i])
    
  }
  return result
}

export const assignIdsToBlocks = (data) => {
  let arr = _.cloneDeep(data)
  if (arr?.blocks?.length > 0) {
    for (let i = 0; i < arr.blocks.length; i++) {
      if (arr.blocks[i].isHideBlock) {
        arr.blocks.splice(i, 1)
        i--
      }
      arr.blocks[i].id = Ids() + Math.floor(Math.random() * 10000) + i
    }
  }
  return arr

}

export const getOptions = async (data, sections) => {
  let arr = []

  if (sections.length > 0) {
    for (let i = 0; i < data.length; i++) {
      let index = sections.findIndex((item) => item.type === data[i].type)
      if (index >= 0)
        arr.push({ ...sections[index], icon: data[i].icon })
    }
  }

  return arr
}

export const getUniqueUpsellName = async (name, arr) => {
  let maxIndex = 0
  for (let i = 0; i < arr.length; i++) {
    let count = arr[i]?.sections?.reduce(function (prev, curr) {
      if (curr.type === 'group') {
        let v = Number(curr.upsell_name.split('(').pop().split(')')[0])
        return prev > v ? prev : v;
      } else {
        return prev
      }
    }, maxIndex);

    maxIndex = count

  }

  let index = name.indexOf('(')
  name = setCharAt(name, index + 1, Number(maxIndex) + 1);
  return name
}

export const setCharAt = (str, index, chr) => {
  if (index > str.length - 1) return str;
  return str.substring(0, index) + chr + str.substring(index + 1);
}