import { useState, useEffect, useContext, Fragment } from 'react';
import { PageData } from '../ContextAPI/context';
import { CreatedNewObj, getTargetedValue, updateTargetedValue, getSelectedSection } from '../Actions/common';
import TextField from './commonComp/TextField';
import { UPDATE_STATE } from '../Constant';
import LocationBanner from './commonComp/LocationBanner';
import { TextEditor } from './commonComp/TextEditor';
import CustomHeader from './commonComp/CustomHeader';
import InputSelect from './commonComp/InputSelect';

function Heading(props) {
  const { PrePurchaseConfigSetting, unifiedEditor, dispatch } = useContext(PageData);

  const [sectionTitle, setSectionTitle] = useState();
  const [sectionSettings, setSectionSettings] = useState([]);
  const { id, sectionID, topSectionID, extentionID, target } = props?.type;


  const handleChange = async (e, target) => {
    let value = e;
    const latestPrepurchaseSettings = await CreatedNewObj(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1]);
    const updatedPrepurchaseSettings = await updateTargetedValue(latestPrepurchaseSettings, target, value, id, sectionID, topSectionID, extentionID);
    if (updatedPrepurchaseSettings) {
      dispatch({ type: UPDATE_STATE, payload: updatedPrepurchaseSettings });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const latestPrepurchaseSettings = PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1];
      const data = getTargetedValue(latestPrepurchaseSettings, target, id, sectionID, topSectionID, extentionID)
      const obj = await getSelectedSection(data, unifiedEditor.sections)
      setSectionTitle(obj?.sectionName);
      setSectionSettings(obj?.sectionSettings);
    }

    fetchData()
  }, [PrePurchaseConfigSetting, unifiedEditor, target, id, sectionID, topSectionID, extentionID]);

  return (
    <div className="section-details-wrap">
      <CustomHeader label={sectionTitle} />
      {!topSectionID && <LocationBanner data={props?.type} />}
      {sectionSettings?.length > 0 &&
        sectionSettings?.map((setting, index) => {
          if (setting.type === 'textEditor') {
            return (
              <Fragment key={index}>
                <TextEditor
                  label={setting.label}
                  tooltipText={setting.tooltipText}
                  defaultText={setting.defaultText}
                  showTooltip={setting.showTooltip}
                  value={getTargetedValue(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1], `${target}.${setting.target}`, id, sectionID, topSectionID, extentionID)}
                  onChange={(e) => handleChange(e, `${target}.${setting.target}`)}
                />
              </Fragment>
            );
          } else if (setting.type === 'textField') {
            return (
              <Fragment key={index}>
                <TextField
                  label={setting.label}
                  value={getTargetedValue(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1], `${target}.${setting.target}`, id, sectionID, topSectionID, extentionID)}
                  onChange={e => handleChange(e, `${target}.${setting.target}`)}
                  toolTipIsEnable={setting.showTooltip}
                  tooltip={setting.tooltipText}
                />
              </Fragment>
            );
          } else if (setting.type === 'dropdown') {
            return (
              <div key={index}>
                <InputSelect
                  isShowTooltip={setting.showTooltip}
                  tooltipContent={XMLDocument.tooltip}
                  label={setting.label}
                  options={setting.options}
                  onChange={e => handleChange(e, `${target}.${setting.target}`)}
                  value={getTargetedValue(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1], `${target}.${setting.target}`, id, sectionID, topSectionID, extentionID)}
                />
              </div>
            );
          } else {
            return null;
          }
        })}
    </div>
  );
}

export default Heading;