import { createContext, useCallback, useEffect, useReducer, useState } from 'react';
import Reducer from './Reducer/product';
import { fetchPreviews, updatePreview, verifyAuth } from '../Service';
import { DisplayText } from '@shopify/polaris';
import _ from 'lodash';
import { AUTH_TOKEN, CHECKOUT, FIRST_TIME_USER_POPUP_DATA, FV, PAGE, REDIRECT_URL, REDO_STATE, REPLACE_STATE, SHOP, TYPE, UNDO_STATE, UPDATE_STATE, WIX } from '../Constant';
import checkout_extention from "../CheckoutEditorSchema/Editor.json";
// import checkout_extention_preview from "../mock/checkout_extention_preview.json";
import pre_purchase from "../mock/pre_purchase.json";
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
// import pre_purchase_preview from "../mock/pre_purchase_preview.json";
// import wix_preview from "../mock/wix_editor_preview.json";
import wix_editor from "../mock/wix_editor.json";
import { CE_BUNDLE_URL, CRISP_WEBSITE_ID, LAUNCH_DARKLY_ENABLE_BANNER_KEY, LAUNCH_DARKLY_ENABLE_DISCOUNT_KEY, LAUNCH_DARKLY_ENABLE_MICROSERVICE_KEY } from '../config';

const bundles = {
  CHECKOUT: CE_BUNDLE_URL,
  PREPURCHASE: './js/pre-purchase-bundle.js',
  WIX: './js/wix-checkout-bundle.js'
}

const editors = {
  CHECKOUT: checkout_extention,
  PREPURCHASE: pre_purchase,
  WIX: wix_editor
}


export const PageData = createContext();
let undoState = [];
const JsonData = ({ children, handleLoading }) => {
  const [unifiedEditor, unifiedEditorState] = useState();
  const [container, ContainerState] = useState([]);
  const [progress, progressState] = useState(false);
  const [PrePurchaseConfigSetting, dispatch] = useReducer(Reducer, []);
  const [isVerify, setIsVerify] = useState(false);
  const [selectedPage, setSelectedPage] = useState('Information');
  const [loading, setLoading] = useState(false)
  const [selectedSection, setSelectedSection] = useState('')
  const [returnUrl, setReturnUrl] = useState('')
  const [hmacRes, setHmacRes] = useState()
  const [rightbarLinks, setRightbarLinks] = useState([])
  const [selectedLink, setSelectedLink] = useState({})
  const [shop, setShop] = useState('')
  const [type, setType] = useState('')
  const [helpModalData, setHelpModalData] = useState({})
  const [isaddUpsellModalOpen, setIsaddUpsellModalOpen] = useState(false)
  const [previewSelectedSections, setPreviewSelectedSection] = useState({ parentId: '', id: '' })
  const [bannerStatus, setBannerStatus] = useState({})
  const [addSectionData, setAddSectionData] = useState()
  const [isHideLeftbarBanner, setIsHideLeftbarBanner] = useState(true)
  const [addStaticUpsell, setAddStaticUpsell] = useState(false)
  const [requiredData, setRequiredData] = useState({ stopChanges: false, showToast: false, showErrorMessage: false })
  const [extra, extraState] = useState({
    colorPicker: {
      status: false,
      id: ''
    },
    addblock: false
  });
  const [isRightbarEmpty, setIsRightbarEmpty] = useState(false);
  const ldClient = useLDClient();
  const flags = useFlags();
  const isDiscountEnabled = flags[LAUNCH_DARKLY_ENABLE_DISCOUNT_KEY] === false ? false : true;
  const isBannerEnable = flags[LAUNCH_DARKLY_ENABLE_BANNER_KEY];
  const isMicroService = flags[LAUNCH_DARKLY_ENABLE_MICROSERVICE_KEY];

  useEffect(() => {
    ldClient && ldClient.identify({ key: shop });
  }, [ldClient, shop]);

  const checkIsAnySectionPresent = useCallback((data, editorType = type) => {
    // this function check customer added any section 
    if (editorType === CHECKOUT && data?.extensions?.length > 0) {
      let arr = data.extensions
      for (let i = 0; i < arr.length; i++) {
        if (arr[i]?.sections?.length > 0) {
          setIsHideLeftbarBanner(false)
          return
        }
      }
      setIsHideLeftbarBanner(true)
    }
  }, [setIsHideLeftbarBanner, type])

  const undoFun = useCallback(() => {
    if (PrePurchaseConfigSetting.length > 1) {
      let obj = _.cloneDeep(PrePurchaseConfigSetting)
      progressState(true);
      undoState = obj.pop();

      if (undoState.sectionAdded) {
        setIsRightbarEmpty(true);
      } else {
        setIsRightbarEmpty(false);
      }

      ContainerState((container) => [...container, undoState]);
      dispatch({ type: UNDO_STATE, payload: obj });
      checkIsAnySectionPresent(obj?.[obj.length - 1])
      setTimeout(() => {
        progressState(false);
      }, 500);
    }
  }, [PrePurchaseConfigSetting, checkIsAnySectionPresent]);

  const redoFun = useCallback(() => {
    if (container.length > 0) {
      let data = container.pop();
      progressState(true);
      dispatch({ type: REDO_STATE, payload: data });
      checkIsAnySectionPresent(data)
      setTimeout(() => {
        progressState(false);
      }, 500);
    }
  }, [container, checkIsAnySectionPresent]);


  const submitData = useCallback(async () => {
    progressState(true);
    const data = PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1];
    let isDataSaved = true

    await updatePreview(data,
      isMicroService,
      type,
      res => {
        dispatch({
          type: REPLACE_STATE,
          payload: [data]
        });
        checkIsAnySectionPresent(data, type)
      },
      err => {
        console.log(err);
        isDataSaved = false
      });
    setTimeout(() => {
      progressState(false);
    }, 1000);
    return isDataSaved
  }, [PrePurchaseConfigSetting, checkIsAnySectionPresent, isMicroService, type]);


  useEffect(() => {
    const handleKeyDown = (e) => {
      if (navigator.userAgentData.platform === 'macOS') {
        if (e.metaKey && e.key === 's') {
          e.preventDefault();
          submitData();
        } else if (e.metaKey && e.key === 'y') {
          e.preventDefault();
          redoFun();
        } else if (e.metaKey && e.key === 'z') {
          e.preventDefault();
          undoFun();
        }
      } else if (navigator.userAgentData.platform === 'Windows') {
        if (e.ctrlKey && e.key === 's') {
          e.preventDefault();
          submitData();
        } else if (e.ctrlKey && e.key === 'y') {
          e.preventDefault();
          redoFun();
        } else if (e.ctrlKey && e.key === 'z') {
          e.preventDefault();
          undoFun();
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [undoFun, redoFun, submitData]);

  useEffect(() => {
    if (isMicroService !== undefined) {
      getInitialData();
      getSelectedPage();
      getReturnUrl();
      checkUserISFirstTime()
      getCheckoutUrl()
      setTimeout(() => {
        progressState(false);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMicroService]);

  const checkUserISFirstTime = () => {
    const params = window.location.search
    let isFirstTime = new URLSearchParams(params).get(FV);
    const type = new URLSearchParams(params).get(TYPE) || "CHECKOUT";
    if (isFirstTime === 'true' && type !== WIX) {
      setHelpModalData(FIRST_TIME_USER_POPUP_DATA)
    } else if (isFirstTime === 'true' && type === WIX) {
      setIsaddUpsellModalOpen(true)
    }
  }

  const getCheckoutUrl = () => {
    const params = window.location.search
    let shop = new URLSearchParams(params).get(SHOP);
    if (shop) {
      setShop(shop)
    }
  }

  const getSelectedPage = () => {
    const params = window.location.search
    const page = new URLSearchParams(params).get(PAGE);

    if (page) {
      setSelectedPage(page)
    }
  }

  const getReturnUrl = () => {
    const params = window.location.search
    const returnUrlStr = new URLSearchParams(params).get(REDIRECT_URL);
    setReturnUrl(returnUrlStr);
  }

  const handlePageChange = (value) => {
    if (isRequiredDataFilled()) {
      setLoading(true)
      setAddSectionData()
      setSelectedPage(value)
      setLoading(false)
    }
  }

  const setDataForEditor = (token = '', type, store_client_id, store_email, shop_owner, shop_name, store_name) => {
    setIsVerify(false);


    let crisp_scid = store_client_id;
    window.$crisp = [];
    window.CRISP_TOKEN_ID = btoa((crisp_scid * 6));
    window.CRISP_WEBSITE_ID = CRISP_WEBSITE_ID;
    window.$crisp.push(["set", "user:email", store_email]);
    window.$crisp.push(["set", "user:nickname", [shop_owner]]);
    window.$crisp.push(["set", "user:company", [shop_name, {
      url: store_name,
    }]]);
    window.$crisp.push(["set", "session:data", [[
      ["url", store_name],
    ]]]);
    (function () {
      let d = document;
      let s = d.createElement("script");
      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();

    //add script for preivew  
    const script = document.createElement("script")
    script.src = bundles[type]
    script.defer = 'defer'
    document.body.appendChild(script)

    //set editor json by type
    unifiedEditorState(editors[type]);
    setType(editors[type]?.type)

    // if (type === WIX) {

    //   dispatch({
    //     type: UPDATE_STATE,
    //     payload: wix_preview
    //   });
    //   checkIsAnySectionPresent(wix_preview, type)

    // } else {
    fetchPreviews(token, isMicroService, type, res_data => {
      // if (checkout_extention && checkout_extention.length > 0) {
      dispatch({
        type: UPDATE_STATE,
        payload: res_data.data[0]
      });
      checkIsAnySectionPresent(res_data.data[0], type)
      // }
    });
    // }

    handleLoading(false)
  }

  const getInitialData = async () => {
    const params = window.location.search
    const type = new URLSearchParams(params).get(TYPE) || "CHECKOUT";

    verifyAuth(
      isMicroService,
      res => {
        localStorage.setItem(AUTH_TOKEN, res.data.token)

        setHmacRes(res.data)
        const store_client_id = res.data?.store_client_id ? res.data.store_client_id : '';
        const store_email = res.data?.store_email ? res.data.store_email : '';
        const shop_owner = res.data?.shop_owner ? res.data.shop_owner : '';
        const shop_name = res.data?.shop_name ? res.data.shop_name : '';
        const store_name = res.data?.store_name ? res.data.store_name : '';
        setDataForEditor(res?.data?.token, type, store_client_id, store_email, shop_owner, shop_name, store_name)

      },
      err => {
        setIsVerify(true);
        handleLoading(false)
      }
    );

  };

  const isRequiredDataFilled = useCallback(() => {
    if (requiredData.stopChanges) {
      if (!requiredData.showErrorMessage) {
        setRequiredData({ ...requiredData, showToast: true, showErrorMessage: true })
      } else {
        setRequiredData({ ...requiredData, showToast: true })
      }
      return false
    } else {
      return true
    }
  }, [requiredData])

  const unAuthHtml = () => (
    <div className="unauth_text">
      <DisplayText size="large">Oops!! Access denied.</DisplayText>
    </div>
  );

  return (
    <>
      <PageData.Provider
        value={{
          PrePurchaseConfigSetting,
          extraState,
          extra,
          dispatch,
          unifiedEditorState,
          unifiedEditor,
          redoFun,
          undoFun,
          container,
          progressState,
          progress,
          submitData,
          selectedPage,
          handlePageChange,
          loading,
          returnUrl,
          selectedSection,
          setSelectedSection,
          rightbarLinks,
          setRightbarLinks,
          hmacRes,
          selectedLink,
          setSelectedLink,
          previewSelectedSections,
          setPreviewSelectedSection,
          type,
          isRightbarEmpty,
          setIsRightbarEmpty,
          shop,
          helpModalData,
          setHelpModalData,
          bannerStatus,
          setBannerStatus,
          addSectionData,
          setAddSectionData,
          isHideLeftbarBanner,
          setIsHideLeftbarBanner,
          checkIsAnySectionPresent,
          setIsaddUpsellModalOpen,
          isaddUpsellModalOpen,
          addStaticUpsell,
          setAddStaticUpsell,
          isDiscountEnabled,
          isBannerEnable,
          requiredData,
          setRequiredData,
          isRequiredDataFilled,
          isMicroService
        }}
      >
        {isVerify ? unAuthHtml() : unifiedEditor === undefined ? null : children}
      </PageData.Provider>
    </>
  );
};
export default JsonData;
