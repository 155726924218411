import { useCallback, useContext, useEffect, useState } from "react"
import { CreatedNewObj, updateTargetedValue, getTargetedValue } from "../../Actions/common"
import { PageData } from "../../ContextAPI/context"
import ProductSelector from "./ProductSelector"
import TagCombobox from "./../commonComp/TagCombobox";
import { UPDATE_STATE, WIX } from "../../Constant";
import InputCheckbox from "../commonComp/InputCheckbox";
import InputSelect from "../commonComp/InputSelect";
import ChakraTagInput from "../commonComp/ChakraTagInput";
import CustomTooltip from "../commonComp/CustomTooltip";

const DisplayComponents = (props) => {
    const { type } = props.data

    switch (type) {
        case "checkbox":
            return <CheckboxComponent data={{ ...props.data }} />
        case "dropdown":
            return <DropDown data={{ ...props.data }} />
        case "tagEditor":
            return <TagEditor data={{ ...props.data }} />
        case "selector":
            return <ProductSelector data={{ ...props.data }} />
        default:
            return ""
    }
}

const TagEditor = ({ data }) => {
    const { type, PrePurchaseConfigSetting, dispatch } = useContext(PageData)
    const [tags, setTags] = useState()
    const { obj, sectionID, topSectionID, target, extentionID } = data;


    useEffect(() => {
        let tagsString = getTargetedValue(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1], target, '', sectionID, topSectionID, extentionID) || '';

        tagsString = tagsString?.trim();
        const tags = tagsString.length > 0 ? tagsString.split(',') : [];
        setTags(tags);
    }, [PrePurchaseConfigSetting, target, sectionID, topSectionID, extentionID]);


    const handleTagsChange = useCallback(async (event, tags) => {
        setTags(tags)

        // create tag string from tags to save in prepurchase
        const nextSelectedTags = new Set([...tags]);
        const tagString = [...nextSelectedTags].join(',');

        const data = await CreatedNewObj(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1]);
        const dataValue = await updateTargetedValue(data, target, tagString, '', sectionID, topSectionID, extentionID);

        dispatch({ type: UPDATE_STATE, payload: dataValue });
    }, [PrePurchaseConfigSetting, extentionID, sectionID, target, topSectionID, dispatch])

    return (
        type === WIX ?
            <>
                <CustomTooltip label={obj.label} text={obj.tooltipText} showTooltip={obj.showTooltip} />
                <ChakraTagInput tags={tags} onTagsChange={handleTagsChange} />
            </>
            :
            <TagCombobox data={{ ...data }} />
    )
}

const DropDown = (props) => {
    const { obj, sectionID, topSectionID, extentionID, target } = props.data

    const { PrePurchaseConfigSetting, dispatch } = useContext(PageData)

    const setValues = async (e) => {
        const data = await CreatedNewObj(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1])
        const dataValue = await updateTargetedValue(data, target, e, '', sectionID, topSectionID, extentionID)
        dispatch({ type: UPDATE_STATE, payload: dataValue })
    }
    return (
        <InputSelect
            isShowTooltip={obj.showTooltip}
            tooltipContent={obj.tooltipText}
            label={obj.label}
            options={obj.options}
            showHelpText={obj.showHelpText}
            onChange={setValues}
            value={getTargetedValue(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1], target, '', sectionID, topSectionID, extentionID)}
        />
    )
}

const CheckboxComponent = (props) => {
    const { obj, sectionID, topSectionID, target, extentionID } = props.data
    const { PrePurchaseConfigSetting, dispatch } = useContext(PageData)

    const setValues = async (e) => {
        const data = await CreatedNewObj(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1])
        const dataValue = await updateTargetedValue(data, target, e, '', sectionID, topSectionID, extentionID)
        dispatch({ type: UPDATE_STATE, payload: dataValue })
    }

    return (
        <InputCheckbox
            label={obj.label}
            checked={getTargetedValue(PrePurchaseConfigSetting[PrePurchaseConfigSetting.length - 1], target, '', sectionID, topSectionID, extentionID)}
            onChange={setValues}
            showTooltip={obj.showTooltip}
            tooltipText={obj.tooltipText}
        />
    )
}

const ProductSwitcher = (props) => {
    const { obj, type, sectionID, topSectionID, extentionID, target, main } = props.value

    return (
        <>
            {
                type ?
                    <div>
                        <DisplayComponents data={{ type: obj.type, obj, sectionID, topSectionID, extentionID, target, main }} />
                    </div>
                    : null
            }
        </>
    )
}


export default ProductSwitcher